import { cn } from '@/lib/utils';

interface LoadingSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  strokeWidth?: number;
  secondary?: boolean;
}

export const LoadingSpinner = ({
  size = 24,
  strokeWidth = 2,
  secondary = false,
  className,
  ...props
}: LoadingSpinnerProps) => {
  return (
    <div
      role="status"
      aria-label="Loading"
      className={cn('inline-flex', className)}
      {...props}
    >
      <svg
        className="animate-spin"
        style={{
          width: size,
          height: size,
        }}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Background circle */}
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
        {/* Spinner arc */}
        <path
          className={cn(
            secondary ? 'stroke-gray-600' : 'stroke-blue-600',
            'dark:stroke-blue-500'
          )}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          d="M 12 2 A 10 10 0 0 1 22 12"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 12 12"
            to="360 12 12"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
