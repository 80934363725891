/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import { create } from 'zustand';
import axios, { AxiosError } from 'axios';
import { ClientStore } from '@/types/ClientStore';
import { Client } from '@/types/Client';

const API_URL = '/api/clients';

const useClientStore = create<ClientStore>((set) => ({
  clients: [],
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  isLoadingUnenroll: false,
  error: null,

  fetchClient: async (id: string, token: string) => {
    try {
      const response = await axios.get(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createClient: async (name: string, email: string, phone: string, dogBreed: string, owner: string, token: string) => {
    set({ isLoadingCreate: true, error: null });
    try {
      const clientData = { name, email, phone, dogBreed, owner };
      const response = await axios.post(API_URL, clientData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const newClient = response.data;
      set((state) => ({
        clients: [...state.clients, newClient],
        isLoadingCreate: false,
      }));
      return newClient;
    } catch (error) {
      set({ isLoadingCreate: false, error: (error as AxiosError).message });
      throw error;
    }
  },

  updateClient: async (client: Client, token: string) => {
    set({ isLoadingUpdate: true, error: null });
    try {
      const response = await axios.put(`${API_URL}/${client.id}`, client, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const updatedClient = response.data;
      set((state) => ({
        clients: state.clients.map((c) => (c.id === client.id ? updatedClient : c)),
        isLoadingUpdate: false,
      }));
      return updatedClient;
    } catch (error) {
      set({ isLoadingUpdate: false, error: (error as AxiosError).message });
      throw error;
    }
  },

  deleteClient: async (id: string, token: string) => {
    set({ isLoadingDelete: true, error: null });
    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      set((state) => ({
        clients: state.clients.filter((client) => client.id !== id),
        isLoadingDelete: false,
      }));
    } catch (error) {
      set({ isLoadingDelete: false, error: (error as AxiosError).message });
      throw error;
    }
  },

  unenrollProgram: async (clientId: string, packageId: string, token: string) => {
    set({ isLoadingUnenroll: true, error: null });
    try {
      await axios.delete(`${API_URL}/${clientId}/packages/${packageId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const updatedClients = useClientStore.getState().clients.map((client) => {
        if (client.id === clientId) {
          return {
            ...client,
            clientPackages: client.clientPackages?.filter((pkg: { id: string }) => pkg.id !== packageId),
          };
        }
        return client;
      });
      set({ clients: updatedClients, isLoadingUnenroll: false });
    } catch (error) {
      set({ isLoadingUnenroll: false, error: (error as AxiosError).message });
      throw error;
    }
  },

  fetchClients: async (token: string) => {
    set({ isLoadingFetch: true, error: null });
    try {
      const response = await axios.get(API_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const clients = response.data;
      set({ clients, isLoadingFetch: false, error: null });
      return clients;
    } catch (error) {
      set({ isLoadingFetch: false, error: (error as AxiosError).message });
      throw error;
    }
  },

  set: (partial: Partial<ClientStore>) => {
    // Update the state with the provided partial object
    set((state) => ({ ...state, ...partial }));
  },
}));

const useFetchClients = (token: string) => {
  const { set } = useClientStore.getState();

  const fetchClients = async () => {
    let retries = 0;
    const maxRetries = 3;
    const delay = 1000;

    try {
      const response = await axios.get(API_URL, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const clients = response.data;

      set({ clients, isLoadingFetch: false, error: null });
      return clients;
    } catch (error) {
      retries++;
      if (retries === maxRetries) {
        const err = error as AxiosError;
        set({ isLoadingFetch: false, error: err.message });
        throw err;
      }
      await new Promise((resolve) => setTimeout(resolve, delay * retries));
      return fetchClients(); // Retry fetching clients
    }
  };

  return fetchClients;
};

export { useClientStore, useFetchClients };
