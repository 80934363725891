// src/store/usePackageStore.ts
'use client';
import { create } from 'zustand';
import axios from 'axios';

import { Package } from '@/types/Package';

interface PackageStore {
  packages: Package[];
  isLoadingFetch: boolean;
  isLoadingCreate: boolean;
  isLoadingUpdate: boolean;
  isLoadingDelete: boolean;
  error: string | null;
  fetchPackages: (token: string) => Promise<Package[]>;
  fetchPackage: (packageId: string, token: string) => Promise<void>;
  createPackage: (packageData: Partial<Package>, token: string) => Promise<void>;
  updatePackage: (packageData: Package, token: string) => Promise<void>;
  updatePackages: (packages: Package[], token: string) => Promise<void>;
  deletePackage: (packageId: string, token: string) => Promise<void>;
}

const usePackageStore = create<PackageStore>((set) => ({
  packages: [],
  isLoadingFetch: false,
  isLoadingCreate: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
  error: null,

  fetchPackages: async (token: string) => {
    set({ isLoadingFetch: true, error: null });
    try {
      const response = await axios.get('/api/packages', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const packages = response.data || [];
      set({ packages, isLoadingFetch: false, error: null });
      return packages;
    } catch (error: any) {
      console.error('Failed to fetch packages:', error.message);
      set({ isLoadingFetch: false, error: error.message, packages: [] });
      return [];
    }
  },

  fetchPackage: async (packageId: string, token: string) => {
    set({ isLoadingFetch: true, error: null });
    try {
      const response = await axios.get(`/api/packages/${packageId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.data) {
        throw new Error('Package not found');
      }

      set((state) => ({
        packages: state.packages.some((p) => p.id === packageId)
          ? state.packages.map((p) => (p.id === packageId ? response.data : p))
          : [...state.packages, response.data],
        isLoadingFetch: false,
        error: null,
      }));
    } catch (error: any) {
      console.error('Failed to fetch package:', error.message);
      set({
        isLoadingFetch: false,
        error: error.message,
        packages: [],
      });
      throw error;
    }
  },

  createPackage: async (packageData: Partial<Package>, token: string) => {
    set({ isLoadingCreate: true, error: null });
    try {
      const response = await axios.post('/api/packages', packageData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      set((state) => ({
        packages: [...state.packages, response.data],
        isLoadingCreate: false,
      }));
    } catch (error: any) {
      console.error('Failed to create package:', error.message);
      set({ isLoadingCreate: false, error: error.message });
    }
  },

  updatePackage: async (packageData: Package, token: string) => {
    set({ isLoadingUpdate: true, error: null });
    try {
      const response = await axios.put('/api/packages', packageData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      set((state) => ({
        packages: state.packages.map((pkg) => (pkg.id === packageData.id ? response.data : pkg)),
        isLoadingUpdate: false,
      }));
    } catch (error: any) {
      console.error('Failed to update package:', error.message);
      set({ isLoadingUpdate: false, error: error.message });
    }
  },

  updatePackages: async (packages: Package[], token: string) => {
    set({ isLoadingUpdate: true, error: null });
    try {
      const updatedPackages = await Promise.all(
        packages.map(async (packageData) => {
          const response = await axios.put('/api/packages', packageData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          return response.data;
        }),
      );
      set((state) => ({
        packages: state.packages.map((pkg) => updatedPackages.find((updatedPkg) => updatedPkg.id === pkg.id) || pkg),
        isLoadingUpdate: false,
      }));
    } catch (error: any) {
      console.error('Failed to update packages:', error.message);
      set({ isLoadingUpdate: false, error: error.message });
    }
  },

  deletePackage: async (packageId: string, token: string) => {
    set({ isLoadingDelete: true, error: null });
    try {
      await axios.delete(`/api/packages`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { id: packageId },
      });
      set((state) => ({
        packages: state.packages.filter((pkg) => pkg.id !== packageId),
        isLoadingDelete: false,
      }));
    } catch (error: any) {
      console.error('Failed to delete package:', error.message);
      set({ isLoadingDelete: false, error: error.message });
    }
  },
}));

export { usePackageStore };
